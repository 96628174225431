<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="客户名称" prop="orgName">
      <div>{{ rowInfo.orgName }}</div>
    </ykc-form-item>
    <ykc-form-item label="可用电站" prop="stationScopeType" required>
      <ykc-radio
        :data="stationScopeType"
        v-model="ruleForm.stationScopeType"
        @change="changeAvailable"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item label="选择电站" prop="dataIdList" v-if="ruleForm.stationScopeType !== '4'">
      <ykc-tree
        v-model="checkAll"
        :data="treeData"
        :props="treeProps"
        :nodeKey="treeNodeKey"
        ref="stationTree"
        @check-change="handleTreeCheckChange"></ykc-tree>
    </ykc-form-item>
    <ykc-form-item label="价格类型" prop="priceType" required>
      <ykc-radio
        :data="priceTypeData"
        v-model="ruleForm.priceType"
        @change="priceTypeChange"></ykc-radio>
    </ykc-form-item>
    <template v-if="isDisabled">
      <ykc-form-item label="合作时间" prop="activityDate">
        <ykc-date-picker limitDate="limitBefore" v-model="ruleForm.activityDate" />
      </ykc-form-item>
      <ykc-form-item label="电费优惠" prop="powerRate">
        <div class="_line">
          <ykc-dropdown
            placeholder="请选择"
            :clearable="false"
            :data="[
              { id: '1', name: '一口价' },
              { id: '2', name: '折扣' },
            ]"
            v-model="ruleForm.powerRateType" />
          <ykc-input
            v-model="ruleForm.powerRate"
            :maxlength="ruleForm.powerRateType === '2' ? 3 : 8"
            :placeholder="`请输入${ruleForm.powerRateType === '2' ? '折扣' : '一口价'}`">
            <template slot="append">
              {{ ruleForm.powerRateType === '2' ? '%' : '元/度' }}
            </template>
          </ykc-input>
        </div>
      </ykc-form-item>
      <ykc-form-item label="服务费优惠" prop="serviceRate">
        <div class="_line">
          <ykc-dropdown
            placeholder="请选择"
            :clearable="false"
            :data="[
              { id: '1', name: '一口价' },
              { id: '2', name: '折扣' },
            ]"
            v-model="ruleForm.serviceRateType" />
          <ykc-input
            v-model="ruleForm.serviceRate"
            :maxlength="ruleForm.serviceRateType === '2' ? 3 : 8"
            :placeholder="`请输入${ruleForm.serviceRateType === '2' ? '折扣' : '一口价'}`">
            <template slot="append">
              {{ ruleForm.serviceRateType === '2' ? '%' : '元/度' }}
            </template>
          </ykc-input>
        </div>
      </ykc-form-item>
    </template>
    <ykc-form-item label="备注" prop="remark">
      <ykc-input
        v-model="ruleForm.remark"
        type="textarea"
        placeholder="备注"
        maxlength="500"></ykc-input>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { barrierPage, treeUtils, bigCustomerPric } from '@/service/apis';
  import { localGetItem } from '@/utils';
  import regexpObj from '@/utils/regexp';

  export default {
    props: {
      rowInfo: {
        type: Object,
      },
    },
    data() {
      return {
        isDisabled: false,
        stationScopeType: [
          {
            id: '1',
            name: '按商户',
          },
          {
            id: '2',
            name: '按电站',
          },
        ],
        detail: {},
        checkAll: 'true',
        ruleForm: {
          id: '',
          orgId: '', // 客户ID
          orgName: '', // 客户名称
          remark: '', // 备注
          dataIdList: [], // 电站数据
          stationScopeType: '1', // 可用电站类型
          exclusivePlatformActivityId: '', // 专用活动id
          activityName: '', // 活动名称
          priceType: '', // 价格类型
          activityDate: [], // 合作时间
          powerRateType: '1', // 电费优惠类型
          powerRate: '', // 电费
          serviceRateType: '1', // 服务费优惠类型
          serviceRate: '', // 服务费
          startTime: '',
          endTime: '',
        },
        treeData: [],
        treeProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        treeNodeKey: 'id',
        priceTypeData: [],
      };
    },
    computed: {
      rules() {
        const initRules = {
          dataIdList: [{ required: true, message: '请选择电站', trigger: 'blur' }],
          remark: [{ required: false, trigger: 'blur', message: '请输入备注' }],
          priceType: [{ required: true, message: '请选择价格类型', trigger: 'blur' }],
          activityDate: [{ required: true, trigger: 'change', message: '请选择合作时间' }],
        };

        let powerRules;
        if (this.ruleForm?.powerRateType === '2') {
          powerRules = [
            { required: true, trigger: 'blur', message: '请输入电费优惠' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error('电费折扣', 100),
              trigger: 'blur',
            },
            { validator: this.checkElectricityDiscount, trigger: 'blur' },
          ];
        } else {
          powerRules = [
            { required: true, trigger: 'blur', message: '请输入电费优惠' },
            {
              pattern: regexpObj.regexp.input.priceNumberType.regexp,
              message: regexpObj.regexp.input.priceNumberType.errorTips.error('电费优惠', 999.9999),
              trigger: 'blur',
            },
            { validator: this.checkElectricityDiscount, trigger: 'blur' },
          ];
        }
        let serviceRlules;
        if (this.ruleForm?.serviceRateType === '2') {
          serviceRlules = [
            { required: true, trigger: 'blur', message: '请输入服务费优惠' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error('服务费折扣', 100),
              trigger: 'blur',
            },
            { validator: this.checkServiceDiscount, trigger: 'blur' },
          ];
        } else {
          serviceRlules = [
            { required: true, trigger: 'blur', message: '请输入服务费优惠' },
            {
              pattern: regexpObj.regexp.input.priceNumberType.regexp,
              message: regexpObj.regexp.input.priceNumberType.errorTips.error(
                '服务费优惠',
                999.9999
              ),
              trigger: 'blur',
            },
            { validator: this.checkServiceDiscount, trigger: 'blur' },
          ];
        }
        console.log('rule udate', powerRules);
        return { ...initRules, powerRate: powerRules, serviceRate: serviceRlules };
      },
    },
    created() {
      this.init();
      const dictionary = localGetItem('dictionary');
      if (dictionary?.largecustomer_price_type) {
        // 0： 原价  1：优惠价
        this.priceTypeData = dictionary.largecustomer_price_type.map(item => ({
          id: item.code,
          name: item.name,
        }));
        // 排下升序
        this.priceTypeData = this.priceTypeData.sort((a, b) => Number(a.id) - Number(b.id));
        console.log(this.priceTypeData, 'this.priceTypeDatathis.priceTypeData');
      } else {
        this.priceTypeData = [
          { id: 0, name: '原价' },
          { id: 1, name: '优惠价' },
        ];
      }
    },
    methods: {
      /* 获取详情 */
      init() {
        const reqParams = {
          id: this.rowInfo.orgId,
        };
        bigCustomerPric.priceConfigDetail(reqParams).then(res => {
          console.log('priceConfigDetail', res);
          this.detail = res || {};
          this.ruleForm.id = this.detail.id;
          this.ruleForm.orgId = this.detail.orgId;
          this.ruleForm.orgName = this.detail.orgName;
          this.ruleForm.exclusivePlatformActivityId = this.detail.exclusivePlatformActivityId;
          this.ruleForm.activityName = this.detail.activityName;
          this.ruleForm.stationScopeType = this.detail.data.stationScopeType;
          this.ruleForm.priceType = String(this.detail.priceType); // 价格类型
          // 优惠价隐藏dom
          if (this.ruleForm.priceType === '1') {
            this.isDisabled = true;
          } else {
            this.isDisabled = false;
          }
          if (this.detail.endTime) {
            this.ruleForm.activityDate = [this.detail.startTime, this.detail.endTime]; // 合作时间
          } else {
            this.ruleForm.activityDate = [];
          }
          this.ruleForm.powerRateType = this.detail.powerRateType; // 电费优惠类型
          this.ruleForm.powerRate = this.detail.powerRate; // 电费
          this.ruleForm.serviceRateType = this.detail.serviceRateType; // 服务费优惠类型
          this.ruleForm.serviceRate = this.detail.serviceRate; // 服务费
          this.ruleForm.remark = this.detail.remark; // 备注
          this.getStationTree();
        });
      },
      /* 获取电站 */
      getStationTree() {
        if (this.ruleForm.stationScopeType === '1') {
          // 按商户选择
          treeUtils
            .operatorStations({})
            .then(res => {
              this.treeProps = {
                label: 'operatorName',
                children: 'subNotes',
                disabled: 'disabled',
              };
              this.treeNodeKey = 'operatorId';
              const listData = res || [];
              const kayMap = { operatorName: 'operatorName', operatorId: 'operatorId' };
              const setEmpty = (data, keyMap) => {
                const objs = Object.keys(data).reduce((newData, key) => {
                  const newKey = keyMap[key] || key;
                  newData[newKey] = data[key];
                  return newData;
                }, {});
                return objs;
              };
              this.$nextTick(() => {
                this.treeData = listData.map(item => {
                  return setEmpty(item, kayMap);
                });
                this.fillInitData();
              });
            })
            .catch(() => {
              this.treeData = [];
            });
        } else if (this.ruleForm.stationScopeType === '2') {
          // 按电站选择
          barrierPage
            .findStationList({})
            .then(res => {
              console.log('findStationList', res);
              this.treeProps = {
                label: 'stationName',
                children: 'subNotes',
                disabled: 'disabled',
              };
              this.$nextTick(() => {
                this.treeNodeKey = 'stationId';
                this.treeData = res || [];
                this.fillInitData();
              });
            })
            .catch(() => {
              this.treeData = [];
            });
        }
        // else if (this.ruleForm.stationScopeType === '3') {
        //   // 按电站分组
        //   treeUtils
        //     .operatorStationGroup({
        //       operatorId: '',
        //     })
        //     .then(res => {
        //       this.treeProps = {
        //         label: 'groupName',
        //         children: 'subNotes',
        //         disabled: 'disabled',
        //       };
        //       this.$nextTick(() => {
        //         this.treeNodeKey = 'id';
        //         this.treeData = res || [];
        //         this.fillInitData();
        //       });
        //     })
        //     .catch(() => {
        //       this.treeData = [];
        //     });
        // }
      },
      /**
       *填充电站回显数据
       */
      fillInitData() {
        // 此处判断是为了解决回显数据后，重新选择可用电站，不选中对应电站也能保存提交，导致数据展示错误的问题
        if (this.ruleForm.stationScopeType === this.detail.data.stationScopeType) {
          if (this.detail.data.dataIdList.length > 0) {
            this.ruleForm.dataIdList = this.detail.data.dataIdList;
            this.ruleForm.dataIdList = this.fillTreeSelectedIds(this.detail.data.dataIdList);
            this.selectTreeNodes(this.$refs.stationTree, this.ruleForm.dataIdList);
          }
        } else {
          this.ruleForm.dataIdList = [];
        }
      },
      /**
       * 选择树形控件
       * @param tree
       * @param selectedNodes
       */
      selectTreeNodes(tree, selectedNodes) {
        if (this.ruleForm.stationScopeType === this.detail.data.stationScopeType) {
          this.$nextTick(() => {
            tree.setCheckedKeys(selectedNodes, true);
          });
        } else {
          selectedNodes = [];
          this.$nextTick(() => {
            tree.setCheckedKeys(selectedNodes, true);
          });
        }
      },
      fillTreeSelectedIds(records) {
        const selectedIds = [];
        records.forEach(item => {
          selectedIds.push(item);
        });
        return selectedIds;
      },
      handleTreeCheckChange() {
        this.ruleForm.dataIdList = [];
        this.ruleForm.dataIdList = this.$refs.stationTree.getCheckedKeys(true);
      },
      /**
       * 价格类型change
       */
      priceTypeChange(key) {
        console.log(key, '价格类型change');
        if (key) {
          switch (key) {
            case '0':
              this.isDisabled = false;
              break;
            case '1':
              this.isDisabled = true;
              break;

            default:
              break;
          }
        }
      },
      changeAvailable(val) {
        console.log('val', val);
        this.getStationTree();
      },
      /* 保存 */
      submitForm() {
        return new Promise(resolve => {
          this.validateForm().then(() => {
            const reqParams = JSON.parse(JSON.stringify(this.ruleForm));
            reqParams.data = {
              dataIdList: this.ruleForm.dataIdList,
              stationScopeType: this.ruleForm.stationScopeType,
            };
            if ((this.ruleForm.activityDate || []).length > 0) {
              const [firstItem, secondItem] = this.ruleForm.activityDate;
              reqParams.startTime = firstItem; // 折扣开始时间
              reqParams.endTime = secondItem; // 折扣结束时间
            }
            delete reqParams.dataIdList;
            delete reqParams.stationScopeType;
            delete reqParams.activityDate;
            bigCustomerPric.priceConfigUpdate(reqParams).then(() => {
              this.$message({
                message: '保存成功',
                type: 'success',
              });
              resolve();
            });
          });
        });
      },
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
</style>
